import React from "react";
import MasonryImg1 from '../../../assets/images/masonry/File_1.jpeg';
import MasonryImg2 from '../../../assets/images/masonry/File_2.jpeg';
import MasonryImg3 from '../../../assets/images/masonry/File_3.jpeg';
import MasonryImg4 from '../../../assets/images/masonry/File_4.jpeg';
import MasonryImg5 from '../../../assets/images/masonry/File_5.jpeg';
import MasonryImg6 from '../../../assets/images/masonry/File_6.jpeg';
import MasonryImg7 from '../../../assets/images/masonry/File_7.jpeg';
import MasonryImg8 from '../../../assets/images/masonry/File_8.jpeg';
import MasonryImg9 from '../../../assets/images/masonry/File_9.jpeg';
import MasonryImg10 from '../../../assets/images/masonry/File_10.jpeg';
import MasonryImg11 from '../../../assets/images/masonry/File_11.jpeg';
import MasonryImg12 from '../../../assets/images/masonry/File_12.jpeg';
import MasonryImg13 from '../../../assets/images/masonry/File_13.jpeg';
import MasonryImg14 from '../../../assets/images/masonry/File_14.jpeg';
import MasonryImg15 from '../../../assets/images/masonry/File_15.jpeg';
import MasonryImg16 from '../../../assets/images/masonry/File_16.jpeg';
import MasonryImg17 from '../../../assets/images/masonry/File_17.jpeg';
import MasonryImg18 from '../../../assets/images/masonry/File_18.jpeg';
import MasonryImg19 from '../../../assets/images/masonry/File_19.jpeg';



export default function AutoScrollGallery() {
    const masonryImages1 = [
        MasonryImg1,
        MasonryImg2,
        MasonryImg14,
        MasonryImg6,
        MasonryImg9,
        MasonryImg8,
        MasonryImg11,
        MasonryImg15,
        MasonryImg16
    ];

    const masonryImages2 = [
        MasonryImg7,
        MasonryImg3,
        MasonryImg12,
        MasonryImg10,
        MasonryImg5,
        MasonryImg13,
        MasonryImg4,
        MasonryImg7,
        MasonryImg17,
        MasonryImg18,
        MasonryImg19
    ];

    const masonryImages = [...masonryImages1, ...masonryImages2];

    const groups = getOrderedGroups(masonryImages, []);
    return (
        <div className="section autoScrollGallery">
            <div class="marquee">
                <div class="marquee-inner">
                    {groups.map(subGroup => {
                        return (
                            <span className="masonry  marquee-image">
                                {subGroup.map((img) => {
                                    return <div className="masonry-item">
                                        <img src={img} />
                                    </div>
                                })}
                            </span>
                        );
                    })}
                    {groups.map(subGroup => {
                        return <span className="masonry  marquee-image">
                            {subGroup.map((img) => {
                                return <div className="masonry-item">
                                    <img src={img} />
                                </div>
                            })}
                        </span>
                    })}
                </div>
            </div>
        </div >
    );

    function getOrderedGroups(masonryImages, arr1) {
        const subGroup = masonryImages.splice(0, 3);
        arr1.push(subGroup);
        if (masonryImages.length > 0) {
            getOrderedGroups(masonryImages, arr1);
        }
        return arr1;
    }
}
import React from "react"
import { Grid, IconButton } from "@mui/material";
import Slider from "react-slick";
import BackIcon from "../../../assets/icons/back-icon.svg"
import ForwardIcon from "../../../assets/icons/forward-icon.svg"

const videos = [
  {
    title: "Carnival 2024 Meetup: A Festive Fiesta of Dance, Games, and Delightful Stalls!",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=7rEt9nXwIKV8DbBY",
  },
  {
    title: "Ahmedabad Meetups 2023",
    url: "https://www.youtube.com/embed/hJsie8i_FI4?si=w0eAo5GFCJOAehr0",
  },
  {
    title: "Bengaluru Meetups 2022",
    url: "https://www.youtube.com/embed/upDrtKAH8Dg?si=V_qm-t3FakqbUHRQ",
  },
  {
    title: "Goa Meetups 2021",
    url: 'https://www.youtube.com/embed/dKq0RhzTIKs?si=LYulZw4BiwbdS7bQ',
  }
  // Add more videos as needed
]

const AnnualTeamMeetups = () => {

  let settings = {
    className: "slider",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    variableWidth: true,
    autoplay: false,
    speed: 2000,
    prevArrow: <BackIcon />,
    nextArrow: <ForwardIcon />,
  };

  return <section className="annual-team-meetups bg-white">
    <Grid container >
      <Grid xs={12}>
        <header className="header">
          <div className="first">Meetups</div>
          <div className='second'>Connecting Beyond Boundaries</div>
        </header>
      </Grid>
      <Grid xs={12} className="content">
        <p>At Zowork, we believe that great things happen when passionate individuals collaborate, no matter where they are in the world. As a fully remote company, our team works from diverse locations, yet we come together multiple times a year for in-person meetups. These moments of connection strengthen our bonds, fuel creativity, and celebrate our shared successes. Explore our meetups through the videos below, capturing the energy and spirit of the Zowork family.</p>
      </Grid>
      <Grid xs={12} className="reasons-list"  >
        <div className="slider-container">
          <Slider {...settings}>
            {videos.map((video, index) => (
              <div key={index} className="video-root">
                <div className="video-container">
                  <iframe
                    src={video.url}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="video-overlay" onClick={() => window.open(video.url, '_blank')} />
                </div>
                <p className="title">{video.title}</p>
              </div>
            ))}
          </Slider>
        </div>
      </Grid>
    </Grid>
  </section>
}
export default AnnualTeamMeetups;
import React from "react";
import VectorQuotes from "../../../assets/icons/vector-quotes.svg";

export default function Message() {
    return (
        <div className="message">
            <div className="icon-vector-quote">
                <VectorQuotes />
            </div>
            <div className="content-container">
                <p className="content">To be one of us is to embrace challenge, embody intelligence, and move quickly. It means you see technology as a means, but the end is always making an impact on our client's most pressing challenges.</p>
                <p className="content">We want you to feel empowered, be free to embrace your inner entrepreneur, and drive to deliver solutions that you are proud of, and this is what the Zowork community is all about.</p>
            </div>
        </div>
    );
}

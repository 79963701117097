import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CareerItem from './careerItem';

const Career = () => {

  useEffect(() => {
    if (window.location.hash) {
      document.getElementById(window.location.hash?.substring(1))?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allAirtable(
        sort: {fields: data___Published_on, order: DESC}
        filter: {data: {Status: {eq: "Open"}}}
      ) {
        edges {
          node {
            data {
              Job_Post
              Status
              Short_Description
              Published_on
              Job_Location
              Experience
              Employment_Status
              Job_Form
            }
            id
          }
        }
      }
    }
  `);

  let jobList = data.allAirtable.edges;

  return (
    <section className="career" id="career">
      <div className="container">
        <div className="header">
          <span className="first">Current</span>
          <span className="second">Openings</span>
        </div>
        {/* <div class="content">Job widget work in progress 😊 <br />Please visit our <a href="https://www.linkedin.com/company/zowork/jobs/">Linkedin</a> page to check out our current job openings. </div> */}
        <div className='career-list-root'>
          {jobList.map(({ node: { id, data: job } }) => {
            return <CareerItem key={id} id={id} jobDetails={job} />
          })}
        </div>
      </div>
    </section>
  );
};

export default Career;

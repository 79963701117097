import React, { useEffect } from "react";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import Seo from '../components/seo';
import DiscoverSection from "../components/JoinUs/DiscoverSection";
import Career from '../components/JoinUs/Career';
import ReasonToWork from "../components/JoinUs/ReasonToWork";
import Message from "../components/JoinUs/Message";
import Humility from "../components/JoinUs/Humility";
import AutoScrollGallery from "../components/JoinUs/AutoScrollGallery";
import VideoGallery from "../components/JoinUs/VideoGallery";
import AnnualTeamMeetups from "../components/JoinUs/AnnualTeamMeetups";

export default function JoinUs(props) {
  useEffect(() => {
    window.navigateFrom = 'join-us';
  }, []);

  return <DefaultLayout {...props}>
    <Seo title="Zowork | For Developers" description="At Zowork, you work with international high quality happy clients, you can work from anywhere. Craft your own life and do not wait for retirement to live your dream life." />
    <div className="join-us-container">
      <DiscoverSection />
      {/* <VideoGallery /> */}
      <AutoScrollGallery />
      <Message />
      <AnnualTeamMeetups/>
      <ReasonToWork />
      <Humility />
      <Career />
    </div>
  </DefaultLayout>
}

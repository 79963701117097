import React from "react"

export default function WorkDetails() {
  return (
    <div className="discover-section">
      <div className="page-bg pen"></div>
      <header className="hero df jcb fc" data-scroll-section="">
        <div className="header-container">
          <h1 className="title serif">Discover</h1>
          <div>
            <h1 className="title serif title-blue">the ZO in You</h1>
          </div>
        </div>
      </header>
    </div>
  )
}

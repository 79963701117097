import React from 'react'
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import moment from 'moment';

const CareerItem = (props) => {
  let { jobDetails: {
    Employment_Status: employmentStatus,
    Experience: experience,
    Job_Location: jobLocation,
    Job_Post: jobPost,
    Job_Form: jobForm
  }, id } = props;

  return <Fade bottom>
    <div className="career-item">
      <div className='left-section'>
        <div className="post-header">
          <div className="job-title">
            {jobPost}
          </div>
          <div className="job-location"><span className="location-icon" />
            {`${jobLocation} | ${experience} | ${employmentStatus}`}
          </div>
        </div>
        <div className="read-more">
          <Link
            to={`${id}`}
            state={{ navigateFrom: 'join-us' }}
          >Read More</Link>
        </div>
      </div>
      <div className='right-section'>
        <a href={jobForm} target="_blank">
          <button className="apply-button">Apply Now</button>
        </a>
      </div>
    </div>
  </Fade>
}

export default CareerItem;
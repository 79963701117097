import React from "react"
import "./video-gallery.css"

const videos = [
  {
    title: "Zowork Carnival 2024",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=mt61j1kahtgDW2CD",
  },
  {
    title: "Ahmedabad 2023 - Meetups by Zowork",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=mt61j1kahtgDW2CD",
  },
  {
    title: "Ahmedabad 2023 - Meetups by Zowork",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=mt61j1kahtgDW2CD",
  },
  {
    title: "Ahmedabad 2023 - Meetups by Zowork",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=mt61j1kahtgDW2CD",
  },
  {
    title: "Ahmedabad 2023 - Meetups by Zowork",
    url: "https://www.youtube.com/embed/AzmKyI_ku_o?si=mt61j1kahtgDW2CD",
  }
  // Add more videos as needed
]

const VideoGallery = () => {
    return (
        <div className="video-gallery-container">
        <h1>Our Team Videos</h1>
        <div className="video-grid">
            {videos.map((video, index) => (
            <div key={index} className="video-item">
                <iframe
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
                <p>{video.title}</p>
            </div>
            ))}
        </div>
        </div>
    )
}
  
export default VideoGallery;